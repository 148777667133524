<template>
  <router-link
    :to="{
      path: '/map',
      query: { d: this.$store.state.viewingdate }
    }"
    >Map</router-link
  >
</template>

<script>
export default {};
</script>
